import type { FC } from 'react';
import { createRoot } from 'react-dom/client';
import initializeSentry from './initializeSentry.ts';

export function render(
  container: HTMLElement,
  Component: FC<Record<string, never>>,
) {
  const element = document.createElement('div');
  element.dataset.react = '';
  container.append(element);
  initializeSentry();

  const root = createRoot(element);
  root.render(<Component />);
}
