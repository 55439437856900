type ZeckEnvironment = 'local' | 'development' | 'uat' | 'production' | 'test';

/**
 * Returns the environment based on the URL.
 * This is kinda awful, but we don't really have a mechanism for front end environment variables.
 */
const getUIEnv = (): ZeckEnvironment => {
  switch (window.location.host) {
    case 'dev.zeck.app': {
      return 'development';
    }
    case 'uat.zeck.app': {
      return 'uat';
    }
    case 'z.zeck.app': {
      return 'production';
    }
    case 'test.zeck.app': {
      return 'test';
    }
  }

  return 'local';
};

export default getUIEnv;
